import React, {Component} from "react"
import styled from 'styled-components'
import {Container, Row, Col} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

import Layout from "components/Layout/Layout"
import Resources from "../components/GroupsPage/Resources"
import EdRated from "../components/GroupsPage/EdRates"
import Map from "../components/HomePage/Map"
import VideoButton from "components/shared/VideoButton"
import VideoModal from "components/shared/VideoModal"
import TopBar from "../components/shared/TopBar";


import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";
import LazyLoad from 'react-lazyload';
import snowFlakesXs from "../images/snowflakes-mobile.svg"
import snowFlakesXl from "../images/snowflakes-desktop.svg"
import GroupRates from "../components/GroupsPage/GroupRates"

const scrollToElement = require('scroll-to-element')

const PageWrapper = styled.div`
  .enhance {
    text-align: center;

    @media ${media.lg} {
      text-align: left;
    }
  }

  .buttonWrapper {
    justify-content: center;
    margin-bottom: 0;

    @media ${media.lg} {
      justify-content: left;
    }
  }

  .fix-height {

    @media (min-width: 1200px) {
      min-height: 350px;
    }

    @media (min-width: 1400px) {
      min-height: 380px;
    }
    @media (min-width: 1500px) {
      min-height: 400px;
    }
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 100%;
  }
`

const SnowFlakes = styled.div`
  background: url("${snowFlakesXs}") repeat 50% 120%;
  background-size: 240%;

  @media ${media.sm} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 290%;
  }

  @media ${media.md} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.lg} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.xl} {
    background: url("${snowFlakesXl}") no-repeat 50% 70%;
    background-size: 180%;
  }
`

const Query = () => (
  <StaticQuery
    query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "HomePage/header-vid-ph-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "HomePage/header-vid-ph-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
              
                FallbackXl: file(relativePath: { eq: "HomePage/header-vid-ph-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                videoPlaceholder2: file(relativePath: { eq: "video-poster-6-1470x828.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
            }
		`}
    render={data => (
      <GroupsPage data={data}/>
    )}
  />
)

class GroupsPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      // src: "https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_stjwndie&flashvars[localizationCode]=en&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=0_txijjqug",
      showImages: false
    }
  }
  
  toggleModal = ( title, src) => {
  
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }
  
  onClick = () => {
    scrollToElement('#bottomOfIframe', {
      duration: 300
    });
  }
  
  render() {
    return (
      <Layout pageName="groups" title="Groups & Education" slug="groups">
        <PageWrapper>
          <TopBar title="Groups & Education"/>
          <SnowFlakes>
            <GroupRates/>
            <EdRated/>
            <Resources/>
            
            <LazyLoad>
              <Container fluid={true} id="behind-the-scenes" className="no-padding-xs">
                <Container>
                  <Row>
                    <Col xl={12} className="my-auto">
                      <hr className="mt-5"/>
                      <h2 className="mb-3 pt-4 text-center">Watch trailer</h2>
                      <div className="fix-height">
                        <VideoButton
                          onClick={() => this.toggleModal( "Watch trailer", "https://secure.disney.com/embed/5e9d164f675b6ebd6b4f2a72?domain=www.disney.co.uk")}
                          aria-label="Open video">
                          <GatsbyImage image={this.props.data.videoPlaceholder2.childImageSharp.gatsbyImageData}
                                       alt="Watch trailer"
                                       title="Watch trailer"
                                       className="img-fluid d-block"
                          />
                        </VideoButton>
                      </div>
                      <hr className="my-5 d-none d-xl-block"/>
                    </Col>
                  
                  </Row>
                </Container>
              </Container>
            </LazyLoad>
            
            <LazyLoad>
              <Map/>
            </LazyLoad>
          </SnowFlakes>
          
          <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src}
                      modalOpen={this.state.modalOpen}/>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Query