import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media"
import {Container} from "reactstrap";

import BgXs from "../../images/HomePage/bg-grad-576.svg"
import BgMd from "../../images/HomePage/bg-grad-991.svg"
import BgXl from "../../images/HomePage/bg-grad-1920.svg"
import lotc from "../../images/LOtC-QB-logo.jpg"
import PreviewModal from '../shared/PreviewModal'
import RelaxedPerformancesModal from '../shared/RelaxedPerformancesModal'
import {Link} from "gatsby"

const Bg = styled.div`
    background: url("${BgXs}") no-repeat center center;
    background-size: cover;
    padding: 2rem 0;
    
    @media ${media.sm} {
        background: url("${BgMd}") no-repeat center center;
        background-size: cover;
        padding: 3rem 2rem;
    }
    
    @media ${media.xl} {
        background: url("${BgXl}") no-repeat center center;
        background-size: cover;
    }
`

const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    
    .narrow {
        max-width: 800px;
    }
    
    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;   
        line-height: 100%;
    }
`

const BoxWrapper = styled.div`
    @media ${media.xl} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: stretch;
    }
    
    .box {
        background: linear-gradient(180deg, #7BF6EF 0%, #FFFFFF 50%);
        width: 100%;
        text-align: center;
        padding: 1rem;
        
        &:nth-child(1) {
            margin-bottom: 2rem;
            
            @media ${media.xl} {
                margin-bottom: 0;
            }
        }
        
        @media ${media.sm} {
            padding: 2rem;
        }
        
        
        h3 {
            font-family: ${props => props.theme.font.family.special};
            color: ${props => props.theme.colors.navy};
            font-size: 24px;
            margin-bottom: 1rem;
            
            @media ${media.sm} {
                font-size: 30px;
            }
            
        }
    }
    
    .btn-wrapper {
        padding: 1rem 0 2rem;
        max-width: 600px;
        
        @media ${media.md} {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .btn {
            margin-bottom: 1rem;
            @media ${media.md} {
                margin-bottom: 0;
            }
        }
    }
`

class Resources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewModalOpen: false,
            relaxedPerformancesModalOpen: false,
        }
    }

    togglePreviewModal = (e, modal) => {
        e.preventDefault();

        this.setState({
            [modal]: !this.state[modal]
        });
    }
    render() {
        return (
            <Bg id={this.props.id}>
                <Container fluid={true}>
                    <Container>
                        {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                            <Content>
                                <h2 className="mb-3 text-center narrower mx-auto">Enrichment and Wellbeing Education Resources</h2>

                                <BoxWrapper>
                                    <div className="box mx-auto">
                                        <div className="narrow mx-auto">
                                            <p>Enhance your pupils' visit with resources about the inspiration for the show, and its transformation from screen to stage via the Enrichment Resources below. For primary schools, accompanying teaching resources focused on wellbeing are now also available. Devised with teachers, they include classroom-based activities on themes of uniqueness, trust, relationships and emotional wellbeing. Download these resources without charge on the National Schools’ Partnership website, via the button below.</p>
                                            <div className="btn-wrapper mx-auto">
                                                <a href="https://www.frozeneducation.co.uk/teaching-resources/wellbeing-resources"
                                                className="btn btn--new d-flex mx-auto text-uppercase no-interstitial-check"><span>wellbeing resource</span></a>
                                                <a href="https://frozeneducation.co.uk/"
                                                   className="btn btn--new d-flex mx-auto text-uppercase no-interstitial-check"><span>enrichment resources</span></a>
                                            </div>

                                            <h3>Frozen Workshops</h3>
                                            <a href="https://groupbooking.disneytickets.co.uk/contact/frozen-workshop/"
                                               className="btn btn--new d-flex mx-auto mb-4 text-uppercase no-interstitial-check"><span>email enquiry</span></a>
                                            <p>School groups can choose between a Movement and Music or Acting workshop. Workshops are available for groups of 20+, ages 6+ and are adapted to group requirements and ability.</p>

                                            <div className='embed-responsive embed-responsive-16by9'>
                                                <iframe className='embed-responsive-item' id="kaltura_player"
                                                        src="https://secure.disney.com/embed/5eda968cec7d2ebd6b4f2a72?domain=www.disney.co.uk"
                                                        width="608" height="608" allowFullScreen webkitallowfullscreen mozAllowFullScreen
                                                        allow="autoplay *; fullscreen *; encrypted-media *"
                                                        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                                                        frameBorder="0" title="Disney Player"></iframe>
                                            </div>

                                            <h3 className="mt-4">Post-show Q&As</h3>
                                            <p>Would your students like to find out more about how we bring the show to life live on stage? Post-show Q&As are available after Thursday matinees when you book your education groups through Disney Tickets.</p>

                                            <a href="https://groupbooking.disneytickets.co.uk/contact/frozen-workshop/?adobe_mc=MCMID%3D17151959967896748612993835620266688032%7CMCORGID%3DCC0A3704532E6FD70A490D44%2540AdobeOrg%7CTS%3D1658843905"
                                               className="btn btn--new d-flex mx-auto mb-4 text-uppercase no-interstitial-check"><span>ENQUIRE HERE</span></a>

                                            <p className="small">Places are limited and based on availability.</p>

                                            <img style={{width: "200px"}} className="d-block mt-2 mb-4 mx-auto" src={lotc} alt="Quality badge" title="Quality badge"/>
                                        </div>
                                    </div>
                                </BoxWrapper>
                            </Content>
                        {/*</ScrollAnimation>*/}
                    </Container>
                </Container>
                <PreviewModal toggleModal={(e) => this.togglePreviewModal(e, "previewModalOpen")} modalOpen={this.state.previewModalOpen}/>
                <RelaxedPerformancesModal toggleModal={(e) => this.togglePreviewModal(e, "relaxedPerformancesModalOpen")} modalOpen={this.state.relaxedPerformancesModalOpen}/>
            </Bg>
        );
    }
}

export default Resources;