import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Container } from "reactstrap";
import styled from "styled-components";
import { media } from "utils/Media";
import PreviewModal from "../shared/PreviewModal";

import SeeTickets from "../../images/grouprates/SeeTICKETS.svg";
import DisneyTickets from "../../images/grouprates/DisneyTickets-black.svg";
import SnowFlake from "../../images/grouprates/snowflake.png";

const GroupRatesBg = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 0 2rem 0;

  @media ${media.md} {
    padding: 0 0 3rem 0;
  }
  .small {
    font-size: 0.85rem;
  }
`;

const GroupRatesContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;

  .narrow {
    max-width: 750px;
  }

  h2 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  align-items: stretch;
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Box = styled.div`
  filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
  background: white;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem auto;

  @media ${media.sm} {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  @media ${media.md} {
    padding: 2rem;
  }

  @media ${media.lg} {
    width: calc(54% - 2rem);
  }

  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;

    @media ${media.sm} {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .small {
    min-height: 14px;
    @media ${media.md} {
      font-size: 15px;
      margin-top: 0.5rem !important;
    }
  }
  p {
    @media ${media.lg} {
      /* min-height: 85px; */
      font-size: 16px;
    }

    @media ${media.xl} {
      font-size: 20px;
      /* min-height: 65px; */
    }
  }
`;

const NewGroupStyles = styled.section`
  width: 100%;
  h2 {
    @media ${media.md} {
      padding: 1.5rem 0 1rem 0;
    }
  }
  section {
    margin: 0 auto;
    padding: 2rem 0;

    .wrapper {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
      align-items: stretch;
      .smaller {
        font-size: 0.85rem !important;
        max-width: 950px;
      }
      .legal {
        margin: 0 auto;
        text-align: center;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
      @media ${media.md} {
        width: 85%;
      }
    }
  }
  .title {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
    h3 {
      margin: 1.5rem 0 0.5rem 0;
    }
  }
  .box_wrapper {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 1100px;
    @media ${media.md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
    @media ${media.lg} {
      grid-gap: 1.5rem;
    }
  }
  .single_box {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 1100px;
  }
`;
const StyledBox = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
  background: white;
  padding: 2rem;
  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.85rem;
  }
  .subtitle {
    font-size: 1.65rem;
  }
  strong {
    font-size: 1.2rem;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    button {
      max-width: 250px;
      margin: 0 auto;
      padding: 0.45rem 0;
      width: 100%;
      display: block;
      background: linear-gradient(135deg, #7bf6ef 0%, #3400a3 100%);
      border: none;
      position: relative;
      cursor: pointer;
      span {
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        position: relative;
        z-index: 5;
      }
      &:hover::before {
        opacity: 1;
      }
      &::before {
        position: absolute;
        content: "";
        inset: 0;
        z-index: 1;
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s linear;
        background: linear-gradient(
          135deg,
          #9a8aff 0%,
          #3b1293 48.96%,
          #000355 100%
        );
      }
    }
    img {
      width: 175px;
      margin-bottom: 0.75rem;
    }
    .number {
      margin-top: 0.5rem;
      font-size: 1.1rem;
      a {
        font-size: 1.2rem;
        font-weight: 900;
        color: #000;
      }
    }
  }

  .snowflake {
    img {
      margin-bottom: 5px;
      width: 20px;
    }
  }
  .smaller {
    font-size: 0.75rem;
    line-height: normal;
    margin-top: 1.5rem;
  }
  @media ${media.md} {
    .two-col {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      max-width: 600px;
      margin: 3rem auto;
      .btn-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 30px;
        }
      }
      button,
      a {
        width: 250px;
      }
    }

    .smaller {
      font-size: 1rem;
    }
  }
  @media ${media.lg} {
    .two-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
  }
`;

const Query = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allChristmasScheduleJson {
          edges {
            node {
              date
              time
            }
          }
        }
        BgImageXs: file(relativePath: { eq: "bg-performances-576.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }

        BgImageSm: file(relativePath: { eq: "bg-performances-991.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        BgImageLg: file(relativePath: { eq: "bg-performances-1200.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 100)
          }
        }

        BgImageXl: file(relativePath: { eq: "bg-performances-1920.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        teaser: file(
          relativePath: {
            eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_1159x1920_AW.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 100)
          }
        }
      }
    `}
    render={(data) => <EdRates id={props.id} data={data} />}
  />
);

class EdRates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewModalOpen: false,
    };
  }

  togglePreviewModal = (e, src) => {
    // e.preventDefault();

    this.setState({
      // src: src,
      previewModalOpen: !this.state.previewModalOpen,
    });
  };

  render() {
    return (
      <GroupRatesBg>
        <NewGroupStyles>
          <div className="title" id="education-rates">
            <h2>Education Rates</h2>
            {/* <h3>EARLY EDUCATION BOOKER RATES AVAILABLE!</h3> */}
            {/* <p>
              Put the best rates on ice when you book and pay 12 weeks in
              advance.{" "}
            </p> */}
          </div>
          <div className="single_box">
            <StyledBox>
              <h3>Education Groups 10+</h3>
              <strong>One free teacher for every 10 pupils booked</strong>
              {/* <h3 className="subtitle mt-4">Early Booker Rates*</h3>
              <strong>
                Stalls &amp; Royal Circle £25{" "}
                <span className="snowflake">
                  <img src={SnowFlake} alt="" />
                </span>{" "}
                Grand Circle £19.50
              </strong>
              <h3 className="subtitle mt-3">Standard Rates</h3>
              <strong>
                Stalls &amp; Royal Circle £29.50{" "}
                <span className="snowflake">
                  <img src={SnowFlake} alt="" />
                </span>{" "}
                Grand Circle £22.50
              </strong>
              <p className="smaller">
                Tickets at the education rate are only available for guests who
                are in full-time education up to (and including) Year 11. One
                accompanying adult per every 10 full-time pupils can attend
                within the education rate, using the free teacher ticket issued
                with every 10 paid tickets. Valid on Wed &ndash; Fri 7pm and Thu 2pm matinees. Excludes school holidays and other
                blackout dates that may apply. Minimum group size is 10.
                Education rate valid for groups up to Year 11. For full terms
                and conditions&nbsp;
                <Link to="/termsandconditions/">click here</Link>.
                <br />
              </p> */}
            </StyledBox>
          </div>
          <div className="single_box">
            <StyledBox>
              <h3>How to Book Your Education Group</h3>
              <strong>
                Enquire for flexible payment terms on reserved bookings.
              </strong>
              <div className="buttons">
                <div className="btn-wrapper">
                  <span>
                    <img src={DisneyTickets} />
                  </span>
                  <a
                    href="https://groupbooking.disneytickets.co.uk/contact/frozen-group-sales/"
                  >
                    <button>
                      <span>ENQUIRE ONLINE</span>
                    </button>
                  </a>
                  <p className="number">
                    Or call <a href="tel:+02078450949">020 7845 0949</a>
                  </p>
                </div>
              </div>
              <p className="smaller">
                Tickets at the education rate are only available for guests who
                are in full-time education up to (and including) Year 11. One
                accompanying adult per every 10 full-time pupils can attend
                within the education rate, using the free teacher ticket issued
                with every 10 paid tickets. Valid on Wed &ndash; Fri 7pm and Thu 2pm matinees. Excludes school holidays and other
                blackout dates that may apply. Minimum group size is 10.
                Education rate valid for groups up to Year 11. For full terms
                and conditions&nbsp;
                <Link to="/termsandconditions/">click here</Link>.
              </p>
            </StyledBox>
          </div>
        </NewGroupStyles>
        <PreviewModal
          toggleModal={this.togglePreviewModal}
          modalOpen={this.state.previewModalOpen}
        />
      </GroupRatesBg>
    );
  }
}

export default Query;

{
  /* <h3>Education Groups 10+</h3> */
}
// <p className="text-center my-0">Stalls &amp; Royal Circle £29.50</p>
// <p className="text-center mt-0">Grand Circle £22.50</p>
// <p className="text-center" onClick={() => this.togglePreviewModal()}><strong className="underline">Early Booker Rates Available</strong></p>
// <p className="text-center color--black narrow mx-auto mt-0">Put the best rates on ice when you pay 12 weeks in advance</p>
// <a href="https://www.seetickets.com/register/disney-s-frozen" className="btn btn--new d-flex mx-auto text-uppercase my-0" target="_blank" rel="noopener noreferrer"><span>Book with SEE Groups</span></a>
// <a href="https://groupbooking.disneytickets.co.uk/contact/frozen-group-sales/" className="btn btn--new d-flex mx-auto text-uppercase my-3" target="_blank" rel="noopener noreferrer"><span>Book with Disney Tickets</span></a>
