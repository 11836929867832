import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import {graphql, StaticQuery} from "gatsby";

const VModal = styled(Modal)`
    max-width: 680px;
    padding: 0;
    border: 0;
    //background: #005F91;
    //opacity: 0.7;

    .modal-content {
        border: 0;
        position: relative;
        border-radius: 0;
    }

    .modal-body {
        padding: 0.5rem;
        border: 0;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 1rem 0 1rem;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;   
        font-size: 24px;
        @media ${media.sm} {
            font-size: 30px;
            margin: 2rem 0 1rem;
        }
    }
`

const Table = styled.div`
    width: 100%;
    max-width: 740px;
    margin: 1rem auto;
    
    @media ${media.sm} {
        padding: 0 2rem;
        margin: 2rem auto;
    }
`

const TableRow = styled.div`
    .tableRow-helper {
        width: 100%;
        display: flex;
        border-top: 1px solid #003199;
    }
    
    .tableCol {
        width: 50%;
        padding: 0.5rem 1rem;
        font-weight: bold;
    }
    
    &:nth-of-type(odd) {
        .tableCol:first-of-type {
            background-color: rgba(121, 246, 240, 0.5);
        }
    }
    
    &:nth-of-type(even) {
        .tableCol:first-of-type {
            background-color: #effbfd;
        }
    }
    
    &:last-of-type {
        border-bottom: 1px solid #003199;
    }
`

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    z-index: 10;
    right: 1rem;
    top: 1rem;
    width: 20px;
    height: 20px;
    border: 2px solid #81E0FF;

    @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    }
    
    &:before{
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: #81E0FF;
        transform: translate(-50%, -50%) rotate(-45deg);
        
        @media (min-width: 992px) {
            background-color: #fff;
        }
    }
    
    &:after{
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: #81E0FF;
        transform: translate(-50%, -50%) rotate(45deg);
        
        @media (min-width: 992px) {
            background-color: #fff;
        }
    }

`

class RelaxedPerformancesModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                <ModalBody>
                    <h3>Access & Relaxed Performances</h3>
                    <Table>
                        {/* <TableRow>
                            <div className="text-center py-2 font-weight-bold">Audio Described</div>
                            <div className="tableRow-helper">
                                <div className="tableCol">Sunday 30 July 2023</div>
                                <div className="tableCol">5:30pm</div>
                            </div>
                        </TableRow> */}
                        {/* <TableRow>
                            <div className="text-center py-2 font-weight-bold">Captioned Performance</div>
                            <div className="tableRow-helper">
                                <div className="tableCol">Sunday 20 August 2023</div>
                                <div className="tableCol">5:30pm</div>
                            </div>
                        </TableRow> */}
                        <TableRow>
                            <div className="text-center py-2 font-weight-bold">Audio Described Performance</div>
                            <div className="tableRow-helper">
                                <div className="tableCol">Friday 19th April 2024</div>
                                <div className="tableCol">7:00pm</div>
                            
                            </div>
                        </TableRow>
                        <TableRow>
                            <div className="text-center py-2 font-weight-bold">Captioned Performance</div>
                            <div className="tableRow-helper">
                                <div className="tableCol" style={{width : '100%'}}>
                            Captioned performance date to be announced. <br /> <br /> Live captioning via GalaPro is now available for all performances of Frozen the Musical. Click&nbsp;<a href="https://lwtheatres.co.uk/gala-pro/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;to find out more information.
                                </div>
                                {/* <div className="tableCol">7:00pm</div> */}
                            </div>
                        </TableRow>
                        <TableRow>
                            <div className="text-center py-2 font-weight-bold">Signed Performance</div>
                            <div className="tableRow-helper">
                                {/* <div className="tableCol">To be announced</div> */}
                                 <div className="tableCol">Sunday 3 March 2024</div>
                                <div className="tableCol">1:00pm</div> 
                            </div>
                        </TableRow>
                        <TableRow>
                            <div className="text-center py-2 font-weight-bold">Relaxed Performance</div>
                            <div className="tableRow-helper">
                                <div className="tableCol">Sunday 28 April 2024</div>
                                <div className="tableCol">1:00pm</div>
                            </div>
                        </TableRow>
                    </Table>
                </ModalBody>
            </VModal>
        );
    }
}

export default RelaxedPerformancesModal;