import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";

const RatesModalStyles = styled.div`
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  padding: 0;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(1, 48, 143, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: relative;
    background: #fff;
    width: 90%;
    height: 50%;
    overflow-y: scroll;
    max-width: 700px;
    max-height: 600px;
    @media ${media.md} {
      width: 75%;
      height: 385px;
      overflow-y: hidden;
    }
  }
  p {
    font-size: 0.85rem;
    @media ${media.md} {
      font-size: 1rem;
    }
  }
  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    @media ${media.sm} {
      font-size: 30px;
      /* margin: 2rem 0 1rem; */
    }
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  background-color: transparent;
  z-index: 10;
  right: 1rem;
  top: 1rem;
  width: 20px;
  height: 20px;
  border: 2px solid #81e0ff;
  cursor: pointer;

  /* @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    } */

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(-45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }

  &:after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }
`;
const RatesModal = ({ children, open, setOpen }) => {
  return open ? (
    <RatesModalStyles onClick={() => setOpen(false)}>
      <section onClick={() => setOpen(false)}>
        <ButtonClose onClick={() => setOpen(false)}>
          <span className="sr-only">Close</span>
        </ButtonClose>
        {children}
      </section>
    </RatesModalStyles>
  ) : (
    ""
  );
};

export default RatesModal;
