import {Link} from "gatsby";
import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {media} from "utils/Media";
import RatesModal from "./RatesModal";

import SeeTickets from "../../images/grouprates/SeeTICKETS.svg";
import DisneyTickets from "../../images/grouprates/DisneyTickets-black.svg";
import SnowFlake from "../../images/grouprates/snowflake.png";

const EarlyBookerStyles = styled.section`
  width: 100%;

  h2 {
    @media ${media.md} {
      padding: 1.5rem 0 1rem 0;
    }
  }

  section {
    margin: 0 auto;
    padding: 2rem 0;

    .wrapper {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
      align-items: stretch;

      .smaller {
        font-size: 0.85rem !important;
        max-width: 950px;
      }

      .legal {
        margin: 0 auto;
        text-align: center;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }

      @media ${media.md} {
        width: 85%;
      }
    }
  }

  .title {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;

    h3 {
      margin: 1.5rem 0 0.5rem 0;
    }
  }

  .box_wrapper {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 1100px;
    @media ${media.md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
    @media ${media.lg} {
      grid-gap: 1.5rem;
    }
  }

  .single_box {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 1100px;
  }
`;
const StyledBox = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
  background: white;
  padding: 2rem;

  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.85rem;
  }

  strong {
    font-size: 1.2rem;
  }

  .snowflake {
    img {
      width: 20px;
      padding-right: 5px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    button {
      max-width: 250px;
      margin: 0 auto;
      padding: 0.45rem 0;
      width: 100%;
      display: block;
      background: linear-gradient(135deg, #7bf6ef 0%, #3400a3 100%);
      border: none;
      position: relative;
      cursor: pointer;

      span {
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        position: relative;
        z-index: 5;
      }

      &:hover::before {
        opacity: 1;
      }

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        z-index: 1;
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s linear;
        background: linear-gradient(135deg,
        #9a8aff 0%,
        #3b1293 48.96%,
        #000355 100%);
      }
    }

    img {
      width: 175px;
      margin-bottom: 0.75rem;
    }

    .number {
      margin-top: 0.5rem;
      font-size: 1.1rem;

      a {
        font-size: 1.2rem;
        font-weight: 900;
        color: #000;
      }
    }
  }

  .smaller {
    font-size: 0.75rem;
    line-height: normal;
    margin-top: 1.5rem;
  }

  @media ${media.md} {
    .two-col {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      max-width: 600px;
      margin: 3rem auto;

      .btn-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          height: 30px;
        }
      }

      button,
      a {
        width: 250px;
      }
    }

    .smaller {
      font-size: 1rem;
    }
  }
  @media ${media.lg} {
    .two-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
  }
`;
const Box = styled.div`
  filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
  background: white;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem auto;

  @media ${media.sm} {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  @media ${media.md} {
    padding: 2rem;
  }

  @media ${media.lg} {
    width: calc(50% - 2rem);
  }

  h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;

    @media ${media.sm} {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .small {
    min-height: 14px;
    @media ${media.md} {
      font-size: 15px;
      margin-top: 0.5rem !important;
    }
  }

  a {
    span {
      color: #fff !important;
    }
  }

  p {
    @media ${media.lg} {
      /* min-height: 85px; */
      font-size: 16px;
    }

    @media ${media.xl} {
      font-size: 20px;
      /* min-height: 65px; */
    }
  }

  ${(props) =>
          props.$larger &&
          css`
            width: 100% !important;
            max-width: 980px;
            margin: 0 auto !important;
          `}
`;
const Table = styled.table`
  width: 95%;
  max-width: 740px;
  margin: 2rem auto;

  tr {
    &:nth-of-type(even) {
      background-color: #effbfd;
    }
  }

  @media ${media.sm} {
    padding: 0 2rem;
    margin: 2rem auto;
  }

  th {
    @media ${media.md} {
      padding: 0.85rem;
    }
  }

  th,
  td {
    border-right: 1px solid #003199;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  th,
  td {
    text-align: center;
  }

  td {
    padding: 0.85rem;
  }

  tr {
    &:first-of-type {
      background-color: #effbfd;

      border-bottom: 1px solid #003199;
    }

    &:nth-of-type(odd) {
      &:first-of-type {
        background-color: rgba(121, 246, 240, 0.5);
      }
    }
  }

  @media ${media.md} {
    font-size: 1rem;
  }
`;
const TableRow = styled.tr`
  .tableRow-helper {
    width: 100%;
    display: flex;
    border-top: 1px solid #003199;
  }

  .tableCol {
    width: 50%;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }

  &:nth-of-type(odd) {
    .tableCol:first-of-type {
      background-color: rgba(121, 246, 240, 0.5);
    }
  }

  &:nth-of-type(even) {
    .tableCol:first-of-type {
      background-color: #effbfd;
    }
  }

  &:last-of-type {
    border-bottom: 1px solid #003199;
  }
`;

const GroupRates = () => {
    const [open, setOpen] = useState(false);
    const [group, setGroup] = useState("");
    const toggle = (group) => {
        setGroup(group);
        setOpen(true);
    };
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else if (!open) {
            document.body.style.overflow = "auto";
        }
    }, [open]);
    return (
        <EarlyBookerStyles>
            <div className="title">
                <h2>Group Rates</h2>
                {/* <h3>Early booker rates available!</h3> */}
                {/* <p>
                    Put the best rates on ice when you book and pay 12 weeks in advance.
                </p> */}
            </div>
            <div className="box_wrapper">
                <StyledBox>
                    <h3>Groups 9 to 19</h3>
                    <strong>Save up to £30 per ticket!</strong>
                    <div className="buttons two-buttons">
                        <button onClick={() => toggle("group9to19")}>
                            <span>VIEW RATES</span>
                        </button>

                        <a
                            href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea?discount=nineplus"
                        >
                            <button>
                                <span>Book online</span>
                            </button>
                        </a>
                    </div>
                </StyledBox>
                <StyledBox>
                    <h3>Groups 20+</h3>
                    <strong>Save up to £30 per ticket!</strong>
                    <div className="buttons">
                        <button onClick={() => toggle("group20")}>
                            <span>VIEW RATES</span>
                        </button>
                    </div>
                </StyledBox>
            </div>
            <div className="single_box">
                <StyledBox>
                    <h3>How to book your group</h3>
                    <strong>
                        Enquire for flexible payment terms on reserved bookings.
                    </strong>
                    <div className="buttons">

                        <div className="btn-wrapper">
                          <span>
                            <img src={DisneyTickets}/>
                          </span>
                            <a
                              className="no-interstitial-check"
                                href="https://groupbooking.disneytickets.co.uk/contact/frozen-group-sales/"
                            >
                                <button>
                                    <span>ENQUIRE ONLINE</span>
                                </button>
                            </a>
                            <p className="number">
                                Or call <a href="tel:+02078450949">020 7845 0949</a>
                            </p>
                        </div>
                    </div>
                    <p className="smaller">
                        Groups 9 &ndash; 19 valid on Wed &ndash; Fri 7pm performances and Thu 2pm matinees. Excludes school holidays and other blackout dates
                        that may apply. Minimum group size is 9. Groups 20+ valid on Wed
                        &ndash; Fri 7pm performances and Thu 2pm matinees. Excludes school
                        holidays and other blackout dates that may apply. Minimum group size
                        is 20. For full terms and conditions&nbsp;
                        <Link to="/termsandconditions/">click here</Link>.
                    </p>
                </StyledBox>
            </div>

            <div className="single_box">
                <StyledBox>
                    <h3>Benefits for Groups</h3>
                    <p><span className="snowflake"><img src={SnowFlake}/></span>Come with friends, family, colleagues or
                        classmates and access great group rates.</p>
                    <p><span className="snowflake"><img src={SnowFlake}/></span>Easy access, with 24 coach bays and
                        numerous pubpc transport options within minutes.</p>
                    <p><span className="snowflake"><img src={SnowFlake}/></span>Fabulous food and drink options in a
                        gloriously refurbished theatre.</p>
                    <p><span className="snowflake"><img src={SnowFlake}/></span>A host of free resources for schools and
                        educators.</p>
                    <p><span className="snowflake"><img src={SnowFlake}/></span>Why not make a day of it? The theatre is
                        minutes away from top London sites and attractions.</p>
                </StyledBox>
            </div>

            {group === "group9to19" ? (
                <RatesModal setOpen={setOpen} open={open}>
                    <h3>Groups 9 to 19</h3>
                    <Table>
                        <tr>
                            <th>Ticket price</th>
                            <th>Group rate</th>
                            
                        </tr>
                        <tr>
                            <td>£57.50 and below</td>
                            <td>£29.50</td>
                            
                        </tr>
                        <tr>
                            <td>£67.50 to £59.50</td>
                            <td>£39.50</td>
                            
                        </tr>
                        <tr>
                            <td>£79.50 to £69.50</td>
                            <td>£49.50</td>
                            
                        </tr>
                    </Table>
                    
                </RatesModal>
            ) : (
                ""
            )}
            {group === "group20" ? (
                <RatesModal setOpen={setOpen} open={open}>
                    <h3>Groups 20+</h3>
                    <Table>
                        <tr>
                            <th>Ticket price</th>
                            <th>Group rate</th>
                        </tr>
                        <tr>
                            <td>£57.50 and below</td>
                            <td>£25</td>
                            
                        </tr>
                        <tr>
                            <td>£67.50 to £59.50</td>
                            <td>£35</td>
                            
                        </tr>
                        <tr>
                            <td>£79.50 to £69.50</td>
                            <td>£45</td>
                            
                        </tr>
                    </Table>
                    
                </RatesModal>
            ) : (
                ""
            )}
        </EarlyBookerStyles>
    );
};
export default GroupRates;
